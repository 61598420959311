import React, { useState } from 'react';
import axios from 'axios';

const MirielApiComponent = (props) => {
    const [query, setQuery] = useState('');
    const [urls, setUrls] = useState('');
    const [document, setDocument] = useState('');  // Input for the document string
    const [queryResult, setQueryResult] = useState(null);
    const [addUrlsResult, setAddUrlsResult] = useState(null);
    const [addStringResult, setAddStringResult] = useState(null);

    // Query API
    const handleQuery = async () => {
        try {
            const response = await axios.post(`${props.backendUrl}/query`, {
                query: query
            });
            setQueryResult(response.data);
        } catch (error) {
            console.error('Query error', error);
        }
    };

    // Add String Function
    const handleAddString = async () => {
        if (!document) {
            alert('Please enter a document string');
            return;
        }

        try {
            const response = await axios.post(`${props.backendUrl}/add_string`, {
                document: document
            });
            setAddStringResult(response.data);
        } catch (error) {
            console.error('Add string error', error);
        }
    };

    // Add URLs Function
    const handleAddUrls = async () => {
        const urlsArray = urls.split(',').map(url => url.trim());

        try {
            const response = await axios.post(`${props.backendUrl}/miriel/add_urls`, {
                urls: urlsArray
            });
            setAddUrlsResult(response.data);
        } catch (error) {
            console.error('Add URLs error', error);
        }
    };

    return (
        <div>
            <h1>Miriel API Test</h1>

            {/* Query Section */}
            <div>
                <h3>Query the API</h3>
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter query"
                />
                <button onClick={handleQuery}>Submit Query</button>
                {queryResult && (
                    <div>
                        <h4>Query Result</h4>
                        <pre>{JSON.stringify(queryResult, null, 2)}</pre>
                    </div>
                )}
            </div>

            {/* Add URLs Section */}
            <div>
                <h3>Add URLs</h3>
                <input
                    type="text"
                    value={urls}
                    onChange={(e) => setUrls(e.target.value)}
                    placeholder="Enter URLs (comma-separated)"
                />
                <button onClick={handleAddUrls}>Submit URLs</button>
                {addUrlsResult && (
                    <div>
                        <h4>Add URLs Result</h4>
                        <pre>{JSON.stringify(addUrlsResult, null, 2)}</pre>
                    </div>
                )}
            </div>

            {/* Add String Section */}
            <div>
                <h3>Add String</h3>
                <input
                    type="text"
                    value={document}
                    onChange={(e) => setDocument(e.target.value)}
                    placeholder="Enter document string"
                />
                <button onClick={handleAddString}>Submit String</button>
                {addStringResult && (
                    <div>
                        <h4>Add String Result</h4>
                        <pre>{JSON.stringify(addStringResult, null, 2)}</pre>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MirielApiComponent;
